// src/components/Athlete.js
import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography } from '@mui/material';
import { setDNS, getRaceDetails } from './api'; 

const Utilities = () => {
    const [status, setStatus] = useState('')
    const [races, setRaces] = useState({});


    // Load devices on first load only
    useEffect(() => {firstLoad();},[])

    const firstLoad = () => {
        getRaceDetails().then((val) => {
            setRaces(val.Races)
        })
    }

    const submitDNS = (bibs) => {
        setDNS(bibs).then((val) => { 
            setStatus(JSON.stringify(val))
        })
    }

    return (
        <div>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}><h2>Utilities</h2></div>

            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography>DNS List</Typography>
                <TextField variant="standard" name="bib" id="biblist" label="Comma Separated Bib List"/>
                <Button variant="contained" sx={{width: 40, marginTop: 4}} onClick={() => {submitDNS(document.getElementById('biblist').value)}}>Submit</Button>
                <Typography variant="caption" align="right">{status}</Typography>
            </div>
            <br/><br/>
            <div>
                <Typography>Mike's Web Reports</Typography>
                { Object.keys(races).forEach(key => (
                    <Typography>{key}: {races[key]}</Typography>
                ))
                }
                
            </div>

        </div>


    );
};

export default Utilities;
